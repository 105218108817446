import React, { useState } from "react";
import Base from "./Base";
import { useDocumentTitle } from "../hooks";
import { ClipLoader } from "react-spinners";
import { useParams } from "react-router-dom";
import SampleInfo from "../components/SampleInfo";
import SampleResequencing from "../components/SampleResequencing";
import Tabs from "../components/Tabs";
import { useFetch } from "../hooks";
import ObjectTitle from "../components/ObjectTitle";
import ObjectAttributes from "../components/ObjectAttributes";
import ExecutionTable2 from "../components/ExecutionsTable";
import DataTable2 from "../components/DataTable";

const SamplePage = () => {
  const { id } = useParams();

  const [sample, setSample] = useState(null);
  const [tab, setTab] = useState("Analysis History");
  const [executionsPage, setExecutionsPage] = useState(1);
  const [executionsCount, setExecutionsCount] = useState(null);
  const [executions, setExecutions] = useState(null);
  const [executionsFilter, setExecutionsFilter] = useState(null);
  const [hasExecutions, setHasExecutions] = useState(null);
  const executionsPageSize = 20;

  const [dataPage, setDataPage] = useState(1);
  const [dataCount, setDataCount] = useState(null);
  const [data, setData] = useState(null);
  const [dataFilter, setDataFilter] = useState(null);
  const [hasData, setHasData] = useState(null);
  const dataPageSize = 25;

  const { loading, status } = useFetch(`/samples/${id}`, {
    onCompleted: data => setSample(data)
  });
  const { loading: typesLoading, data: types } = useFetch("/samples/types");

  useDocumentTitle(sample ? `${sample.name} - Flow` : "Flow");

  const { loading: executionsLoading } = useFetch(`/samples/${id}/executions`, {
    params: {page: executionsPage, count: executionsPageSize, filter: executionsFilter},
    onCompleted: data => {
      setExecutionsCount(data.count);
      setExecutions(data.executions);
      if (hasExecutions === null) setHasExecutions(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / executionsPageSize);
      if (executionsPage > maxPossiblePage) setExecutionsPage(maxPossiblePage || 1);
    }
  })

  const { loading: dataLoading } = useFetch(`/samples/${id}/data`, {
    params: {page: dataPage, count: dataPageSize, filter: dataFilter},
    onCompleted: data => {
      setDataCount(data.count);
      setData(data.data);
      if (hasData === null) setHasData(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / dataPageSize);
      if (dataPage > maxPossiblePage) setDataPage(maxPossiblePage || 1);
    }
  })

  if (loading || typesLoading) return <Base loading={true} />

  if (status === 404) return <Base notFound={true} />

  const metadataForType = types.find(t => t.id === sample.sample_type)?.metadata;

  return (
    <Base>
      <ObjectTitle 
        isPrivate={sample.private}
        title={sample.name} 
        type="sample"
        editUrl={sample.can_edit && `/samples/${id}/edit`}
      />
      <ObjectAttributes object={sample} className="mb-4 md:mb-6" />
      <SampleInfo
        sample={sample}
        className="mb-6 text-xs sm:text-sm md:mb-8 lg:mb-10"
        customMetadata={metadataForType}
      />
      <Tabs
        selected={tab}
        labels={["Analysis History", "Data", "Resequencing"]}
        setSelected={setTab}
      />
      {tab === "Analysis History" && (
        <>
          {!executionsLoading && !hasExecutions && (
            <div className="text-sm mb-6 info sm:mb-8">
              This sample has not been analysed yet.
            </div>
          )}
          {executionsLoading && !hasExecutions && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {hasExecutions && (
            <ExecutionTable2
              executions={executions}
              page={executionsPage}
              pageSize={executionsPageSize}
              setPage={setExecutionsPage}
              totalCount={executionsCount}
              loading={executionsLoading}
              onFilter={s => setExecutionsFilter(s || null)}
              noMessage="No executions match that term." 
            />
          )}
        </>
      )}
      {tab === "Data" && (
        <>
          {!dataLoading && !hasData && (
            <div className="text-sm mb-6 info sm:mb-8">
              This sample has no data.
            </div>
          )}
          {dataLoading && !hasData && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {hasData && (
            <DataTable2
              data={data}
              page={dataPage}
              pageSize={dataPageSize}
              setPage={setDataPage}
              totalCount={dataCount}
              loading={dataLoading}
              onFilter={d => setDataFilter(d || null)}
              noMessage="No data matches that term." 
            />
          )}
        </>
      )}
      {tab === "Resequencing" && <SampleResequencing sample={sample} />}
    </Base>
  )
}

SamplePage.propTypes = {
  
};

export default SamplePage;