import { useContext, useState } from "react";
import Base from "./Base";
import Tabs from "../components/Tabs";
import { useFetch, useDocumentTitle } from "../hooks";
import ProjectsGrid2 from "../components/ProjectsGrid";
import { ClipLoader } from "react-spinners";
import { UserContext } from "../contexts";
import { Link, useParams } from "react-router-dom";

const YourProjectPage = () => {

  const { slug } = useParams();

  const [tab, setTab] = useState("Owned");
  const [user,] = useContext(UserContext);
  const [ownedProjectsPage, setOwnedProjectsPage] = useState(1);
  const [ownedProjectsCount, setOwnedProjectsCount] = useState(null);
  const [ownedProjects, setOwnedProjects] = useState(null);
  const [ownedProjectsFilter, setOwnedProjectsFilter] = useState(null);
  const [hasOwnedProjects, setHasOwnedProjects] = useState(null);
  const [sharedProjectsPage, setSharedProjectsPage] = useState(1);
  const [sharedProjectsCount, setSharedProjectsCount] = useState(null);
  const [sharedProjects, setSharedProjects] = useState(null);
  const [sharedProjectsFilter, setSharedProjectsFilter] = useState(null);
  const [hasSharedProjects, setHasSharedProjects] = useState(null);
  const pageSize = 12;

  useDocumentTitle("Your Projects - Flow");

  const { loading: ownedProjectsLoading } = useFetch("/projects/owned", {
    params: {page: ownedProjectsPage, count: pageSize, filter: ownedProjectsFilter, group: slug},
    onCompleted: data => {
      setOwnedProjectsCount(data.count);
      setOwnedProjects(data.projects);
      if (hasOwnedProjects === null) setHasOwnedProjects(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / pageSize);
      if (ownedProjectsPage > maxPossiblePage) setOwnedProjectsPage(maxPossiblePage || 1);
    }
  })

  const { loading: sharedProjectsLoading } = useFetch("/projects/shared", {
    params: {page: sharedProjectsPage, count: pageSize, filter: sharedProjectsFilter, group: slug},
    onCompleted: data => {
      setSharedProjectsCount(data.count);
      setSharedProjects(data.projects);
      if (hasSharedProjects === null) setHasSharedProjects(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / pageSize);
      if (sharedProjectsPage > maxPossiblePage) setSharedProjectsPage(maxPossiblePage || 1);
    }
  })

  const textClass = "text-sm mb-6 max-w-xl info sm:mb-8";

  const group = user.memberships.find(g => g.slug === slug);

  const ownedLabel = (hasOwnedProjects === false) ? group ? "This group does not own any projects yet." : "You do not own any projects yet." : group ? "Projects owned by this group." : "Projects you own.";
  const sharedLabel = (hasSharedProjects === false) ? group ? "This group has no shared projects yet." : "You do not have any shared projects yet." : group ? "Projects shared with this group." : "Projects shared with you.";
  const showOwnedTable = (hasOwnedProjects === true);
  const showSharedTable = (hasSharedProjects === true);

  return (
    <Base>
      <h1>{group ? group.name : "Your"} Projects</h1>
      <Tabs labels={["Owned", "Shared"]} selected={tab} setSelected={setTab} />
      {tab === "Owned" && (
        <div>
          <div className={textClass}>
            <span>{ownedLabel}</span>
            {user.memberships.length > 0 && !group && (
              <span>
                {" "}Your group{user.memberships.length > 1 ? "s" : ""} may also own projects:{" "}
                {user.memberships.map((g, i) => (
                  <Link key={g.slug} to={`/projects/@${g.slug}`} className="text-flow-blue-7">
                    {g.name}
                    {i < user.memberships.length - 1 ? ", " : "."}
                  </Link>
                ))}
              </span>
            )}
          </div>
          {ownedProjectsLoading && !showOwnedTable && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {showOwnedTable && (
            <ProjectsGrid2
              projects={ownedProjects}
              page={ownedProjectsPage}
              pageSize={pageSize}
              setPage={setOwnedProjectsPage}
              totalCount={ownedProjectsCount}
              loading={ownedProjectsLoading}
              onFilter={s => setOwnedProjectsFilter(s || null)}
              noMessage="No owned projects matches that term." 
            />
          )}
        </div>
      )}
      {tab === "Shared" && (
        <div>
          <div className={textClass}>
            <span>{sharedLabel}</span>
            {user.memberships.length > 0 && !group && (
              <span>
                {" "}Your group{user.memberships.length > 1 ? "s" : ""} may also have projects shared with {user.memberships.length > 1 ? "them" : "it"}:{" "}
                {user.memberships.map((g, i) => (
                  <Link key={g.slug} to={`/projects/@${g.slug}`} className="text-flow-blue-7">
                    {g.name}
                    {i < user.memberships.length - 1 ? ", " : "."}
                  </Link>
                ))}
              </span>
            )}
          </div>
          {sharedProjectsLoading && !showSharedTable && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {showSharedTable && (
            <ProjectsGrid2
              projects={sharedProjects}
              page={sharedProjectsPage}
              pageSize={pageSize}
              setPage={setSharedProjectsPage}
              totalCount={sharedProjectsCount}
              loading={sharedProjectsLoading}
              onFilter={s => setSharedProjectsFilter(s || null)}
              noMessage="No shared projects matches that term."
            />
          )}
        </div>
      )}
    </Base>
  );

}

YourProjectPage.propTypes = {
  
};

export default YourProjectPage;