import { useState } from "react";
import PropTypes from "prop-types";
import DataRow from "./DataRow";
import Paginator from "./Paginator";
import { ClipLoader } from "react-spinners";
import TableFilter from "./TableFilter";
import BulkDeleteButton from "./BulkDeleteButton";
import BulkDownloadButton2 from "./BulkDownloadButton";

const DataTable = props => {

  const { data, page, setPage, pageSize, totalCount, onFilter, loading, showHeader, noMessage, onBulkDelete } = props;

  const [selectedIds, setSelectedIds] = useState([]);

  const dataChecked = d => {
    if (selectedIds.includes(d.id)) {
      setSelectedIds(selectedIds.filter(id => id !== d.id))
    } else {
      setSelectedIds([...selectedIds, d.id])
    }
  }

  const selectedOnPage = data.filter(d => selectedIds.includes(d.id));
  const anySelected = selectedOnPage.length > 0;

  const totalPages = Math.ceil(totalCount / pageSize);

  const thClass = "font-medium text-sm text-flow-gray-7 bg-[#E7E9F0] bg-opacity-80 whitespace-nowrap py-1.5 text-left px-4";

  return (
    <div className="relative" style={{minHeight: 250}}>
      <div className="flex flex-wrap gap-2 justify-between items-center mb-3">
        <div className="flex flex-wrap gap-2 items-center">
          {onFilter && <TableFilter filter={onFilter} />}
          {totalPages > 1 && (
            <Paginator
              currentPage={page} totalPages={totalPages} onChange={setPage}
            />
          )}
        </div>
        {totalCount > 0 && (
          <div className="flex flex-wrap gap-2 items-center">
            {data.length > 0 && (
              <div
                className="text-xs link w-fit"
                onClick={() => setSelectedIds(anySelected ? [] : [...selectedIds, ...data.map(d => d.id)])}
              >
                {anySelected ? "Deselect All" : "Select All"}
              </div>
            )}
            <div className="flex flex-wrap gap-2 items-center">
              <BulkDownloadButton2 selectedIds={selectedIds} />
              {onBulkDelete && (
                <BulkDeleteButton
                  selectedIds={selectedIds}
                  onDelete={onBulkDelete}
                  setSelectedIds={setSelectedIds}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {data.length === 0 && !loading && (
        <div className="mt-8 italic text-sm">
          <span className="info border-l-red-200">{noMessage}</span>
        </div>
      )}
      {loading && (
        <div className="absolute z-40 h-48 w-full flex justify-center items-center">
          <ClipLoader size={100} speedMultiplier={2} />
        </div> 
      )}
      <div className="overflow-y-auto rounded shadow w-full relative">
        <table className={`border-collapse w-full border-0 overflow-hidden ${loading ? "opacity-50 pointer-events-none" : ""}`}>
          {showHeader && data && data.length > 0 &&(
            <thead>
              <tr>
                <th className={thClass} />
                {data && data.some(s => "private" in s) && <th className={thClass}>Privacy</th>}
                <th className={thClass}>Filename</th>
                <th className={thClass}>Size</th>
                <th className={thClass}>Created</th>
                {data && data.some(s => s.owner_name) && <th className={thClass}>Owner</th>}
                <th className={thClass}>Source</th>
                <th className={thClass}>Sample</th>
              </tr>
            </thead> 
          )}
          <tbody>
            {data.map((d, index) => (
              <DataRow
                data={d} key={d.id} index={index}
                checked={selectedIds.includes(d.id)}
                onCheck={() => dataChecked(d)}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

DataTable.propTypes = {
  data: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onFilter: PropTypes.func,
  loading: PropTypes.bool,
  showHeader: PropTypes.bool,
  noMessage: PropTypes.string.isRequired,
  onBulkDelete: PropTypes.func,
};

export default DataTable;