import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDocumentTitle, useFetch} from "../hooks";
import Base from "./Base";
import ObjectTitle from "../components/ObjectTitle";
import PipelineVersions from "../components/PipelineVersions";
import { ClipLoader } from "react-spinners";
import PipelineInputsForm from "../components/PipelineInputsForm";
import PipelineRepoLink from "../components/PipelineRepoLink";

const PipelinePage = () => {
  const { id } = useParams();

  const [pipeline, setPipeline] = useState(null);
  const [pipelineVersionId, setPipelineVersionId] = useState(null);
  const [nextflowVersion, setNextflowVersion] = useState(null);
  const [pipelineVersion, setPipelineVersion] = useState(null);
  const [filesets, setFilesets] = useState(null);

  const { loading: pipelineLoading, status } = useFetch(`/pipelines/${id}`, {
    onCompleted: data => {
      setPipeline(data);
      setPipelineVersionId(data.versions[0].id);
    },
    skip: !id
  });

  const { loading: versionLoading } = useFetch(`/pipelines/versions/${pipelineVersionId}`, {
    onCompleted: data => {
      setPipelineVersion(data);
      if (!nextflowVersion) setNextflowVersion(data.nextflow_versions[0]);
      if (nextflowVersion && !data.nextflow_versions.includes(nextflowVersion)) {
        setNextflowVersion(data.nextflow_versions[0]);
      }
    },
    skip: !pipelineVersionId
  });

  const { loading: filesetsLoading } = useFetch("/filesets/search", {
    params: { has_organism: true, count: 100 },
    onCompleted: data => {
      setFilesets(data.filesets);
    },
    skip: !pipeline || !pipeline.prepares_genome
  });


  useDocumentTitle(pipeline ? `${pipeline.name} - Flow` : "Flow");

  if (pipelineLoading) return <Base loading={true} />

  if (status === 404) return <Base notFound={true} />

  return (
    <Base>
      <ObjectTitle
        title={pipeline.name}
        type="pipeline"
        className="mb-3"
        corner={<PipelineRepoLink pipeline={pipeline} />}
      />
      <PipelineVersions
        pipelineVersions={pipeline.versions}
        pipelineVersionId={pipelineVersionId}
        setPipelineVersionId={setPipelineVersionId}
        nextflowVersions={pipelineVersion ? pipelineVersion.nextflow_versions : []}
        nextflowVersion={nextflowVersion}
        setNextflowVersion={setNextflowVersion}
      />
      {versionLoading && <div className="flex justify-center items-center h-44"><ClipLoader  size={100} speedMultiplier={1.5} /></div>}
      {(pipelineVersion && !versionLoading && !filesetsLoading) && (
        <div>
          <div className="text-flow-gray-8 info text-sm max-w-2xl pl-2 mb-6 md:mb-8 lg:max-w-4xl lg:text-base lg:pl-3">
            {pipelineVersion.long_description}
          </div>
          <PipelineInputsForm
            schema={pipelineVersion.schema}
            versionId={pipelineVersion.id}
            nextflowVersion={nextflowVersion}
            filesets={pipeline.prepares_genome ? filesets : null}
            upstreamPipelineVersions={pipelineVersion.upstream_pipeline_versions}
          />
        </div>
      )}
    </Base>
  );
};

PipelinePage.propTypes = {
  
};

export default PipelinePage;