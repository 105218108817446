import PropTypes from "prop-types";
import Paginator from "./Paginator";
import { ClipLoader } from "react-spinners";
import TableFilter from "./TableFilter";
import ProjectRow from "./ProjectRow";

const ProjectsTable = props => {

  const { projects, page, setPage, pageSize, totalCount, onFilter, showHeader, loading, noMessage } = props;

  const totalPages = Math.ceil(totalCount / pageSize);

  const thClass = "font-medium text-sm text-flow-gray-7 bg-[#E7E9F0] bg-opacity-80 whitespace-nowrap py-1.5 text-left px-4";

  return (
    <div className="relative" style={{minHeight: 250}}>
      <div className="flex flex-wrap gap-2 justify-between items-center mb-3">
        <div className="flex flex-wrap gap-2 items-center">
          {onFilter && <TableFilter filter={onFilter} />}
          {totalPages > 1 && (
            <Paginator
              currentPage={page} totalPages={totalPages} onChange={setPage}
            />
          )}
        </div>
      </div>
      {projects.length === 0 && !loading && (
        <div className="mt-8 italic text-sm">
          <span className="info border-l-red-200">{noMessage}</span>
        </div>
      )}
      {loading && (
        <div className="absolute z-40 h-48 w-full flex justify-center items-center">
          <ClipLoader size={100} speedMultiplier={2} />
        </div> 
      )}
      <div className="overflow-y-auto rounded shadow w-full relative">
        <table className={`border-collapse w-full border-0 overflow-hidden ${loading ? "opacity-50 pointer-events-none" : ""}`}>
          {showHeader && projects && projects.length > 0 &&(
            <thead>
              <tr>
                <th className={thClass} />
                <th className={thClass}>Name</th>
                <th className={thClass}>Samples</th>
                <th className={thClass}>Created</th>
                {projects && projects.some(s => s.owner_name) && <th className={thClass}>Owner</th>}
              </tr>
            </thead> 
          )}
          <tbody>
            {projects.map((project, index) => (
              <ProjectRow project={project} key={project.id} index={index} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

ProjectsTable.propTypes = {
  projects: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onFilter: PropTypes.func,
  loading: PropTypes.bool,
  noMessage: PropTypes.string.isRequired,
  onBulkDelete: PropTypes.func,
  showSource: PropTypes.bool,
  showPurificationTarget: PropTypes.bool,
  showHeader: PropTypes.bool
};

export default ProjectsTable;