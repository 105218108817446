import { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

const RangeSlider = props => {
  const { min, max, value1, value2, setValue1, setValue2 } = props;

  const slots = max - min + 1;

  const [circle1Moving, setCircle1Moving] = useState(false);
  const [circle2Moving, setCircle2Moving] = useState(false);

  const ref1 = useRef(null);
  const ref2 = useRef(null);

  useEffect(() => {
    const updateValue = (clientX, ref, setter, limitValue, isLower) => {
      const parent = ref.current.parentElement.getBoundingClientRect();
      const parentLeft = parent.left;
      const parentWidth = parent.width;
      const pc = Math.min(Math.max((clientX - parentLeft) / parentWidth, 0), 1);
      const value = pc * (max - min) + min;
      const intValue = Math.round(value);
      if (isLower) {
        setter(Math.min(intValue, limitValue - 1));
      } else {
        setter(Math.max(intValue, limitValue + 1));
      }
    };

    const onMouseMove = e => {
      if (circle1Moving) {
        updateValue(e.clientX, ref1, setValue1, value2, true);
      }
      if (circle2Moving) {
        updateValue(e.clientX, ref2, setValue2, value1, false);
      }
    };

    const onTouchMove = e => {
      // Prevent scrolling while interacting with the slider.
      e.preventDefault();
      const touch = e.touches[0];
      if (circle1Moving) {
        updateValue(touch.clientX, ref1, setValue1, value2, true);
      }
      if (circle2Moving) {
        updateValue(touch.clientX, ref2, setValue2, value1, false);
      }
    };

    const onEnd = () => {
      setCircle1Moving(false);
      setCircle2Moving(false);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onEnd);

    document.addEventListener("touchmove", onTouchMove, { passive: false });
    document.addEventListener("touchend", onEnd);
    document.addEventListener("touchcancel", onEnd);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onEnd);
      document.removeEventListener("touchmove", onTouchMove);
      document.removeEventListener("touchend", onEnd);
      document.removeEventListener("touchcancel", onEnd);
    };
  }, [circle1Moving, circle2Moving, value1, value2, setValue1, setValue2, slots, min, max]);

  const circleClass = "w-4 h-4 rounded-full bg-flow-blue-7 cursor-pointer absolute -top-1.5";

  return (
    <div className="relative w-full"> 
      <div className="h-1 bg-flow-gray-3 rounded-full w-full" />
      <div
        className={circleClass}
        style={{ left: `calc(${(value1 / (slots - 1)) * 100}% - 0.5rem)` }}
        onMouseDown={() => setCircle1Moving(true)}
        onTouchStart={() => setCircle1Moving(true)}
        ref={ref1}
      />
      <div
        className={circleClass}
        style={{ left: `calc(${(value2 / (slots - 1)) * 100}% - 0.5rem)` }}
        onMouseDown={() => setCircle2Moving(true)}
        onTouchStart={() => setCircle2Moving(true)}
        ref={ref2}
      />
    </div>
  );
};

RangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value1: PropTypes.number.isRequired,
  value2: PropTypes.number.isRequired,
  setValue1: PropTypes.func.isRequired,
  setValue2: PropTypes.func.isRequired,
};

export default RangeSlider;
