import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

const Tabs = props => {

  const { labels, selected, setSelected } = props;

  const [barLeft, setBarLeft] = useState(0);
  const [barWidth, setBarWidth] = useState(null);

  const ref = useRef();

  const labelClass = "transition-[color]";
  const selectedClass = "text-flow-blue-7";
  const unselectedClass = "cursor-pointer";

  const changeBar = selected => {
    if (ref.current) {
      const refLeft = ref.current.getBoundingClientRect().left;
      const selectedDiv = [...ref.current.childNodes].filter(n => n.innerText === selected)[0];
      const box = selectedDiv.getBoundingClientRect();
      setBarLeft(box.left - refLeft);
      setBarWidth(box.width);
    }
  }

  useEffect(() => {
    const change = () => changeBar(selected);
    changeBar(selected)
    window.addEventListener("resize", change);
    return () => window.removeEventListener("resize", change);
  }, [selected])
  

  return (
    <div className="text-sm flex mb-4 text-flow-gray-6 flex-shrink-0 font-medium gap-4 tracking-tighter whitespace-nowrap overflow-x-auto pb-1 relative sm:text-base sm:gap-6 sm:mb-6 sm:pb-2" ref={ref}>
      {labels.map(label => (
        <div
          key={label} onClick={() => setSelected(label)}
          className={`${labelClass} ${label === selected ? selectedClass : unselectedClass}`}
        >{label}</div>
      ))}
      <div className="absolute bottom-px left-0 w-full h-px bg-[#F2F2F2]" />
      <div
        className="w-12 h-1 bg-flow-blue-7 absolute bottom-0 transition-all"
        style={{left: barLeft, width: barWidth || 24 }}
      />
    </div>
  );
};

Tabs.propTypes = {
  labels: PropTypes.array.isRequired,
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default Tabs;