import React from "react";
import PropTypes from "prop-types";
import { formatTimestamp } from "../utils";
import { Link } from "react-router-dom";
import PrivacyIcon from "./PrivacyIcon";

const ProjectRow = props => {

  const { project, index } = props;

  const Cell = props => {
    const link = props.link !== false;
    const Element = link ? Link : "div";
    return (
      <td className={`whitespace-nowrap text-xs ${props.className || ""}`} onClick={props.onClick}>
        <Element to={`/projects/${project.id}/`} className={`block py-3 ${props.noPx ? "px-0" : "px-4"}`}>
          {props.children}
        </Element>
      </td>
    )
  }

  return (
    <tr className={`bg-opacity-80 hover:bg-opacity-100 ${index % 2 ? "bg-[#E7E9F0]" : "bg-[#F6F6F6]"}`}>
      {"private" in project && (
        <Cell className="pl-4" noPx={true}>
          <PrivacyIcon object={project} className={project.private ? "w-3 opacity-60" : "w-3.5"} />
        </Cell>
      )}
      <Cell>{project.name}</Cell>
      {project.sample_count > 0 && <Cell>{project.sample_count} sample{project.sample_count !== 1 ? "s" : ""}</Cell>}
      {project.sample_count === 0 && <Cell></Cell>}
      <Cell>{formatTimestamp(project.created)}</Cell>
      {project.owner_name && <Cell>{project.owner_name}</Cell>}
    </tr>
  );
};

ProjectRow.propTypes = {
  project: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default ProjectRow;