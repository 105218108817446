import { useState } from "react";
import { useFetch } from "../hooks";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ClipLoader } from "react-spinners";

const UsagePanel = () => {

  const { loading: analyticsLoading, data: analytics } = useFetch("/analytics");
  const { loading: dauLoading, data: dau } = useFetch("/dau");
  const [post, setPost] = useState(false);

  if (analyticsLoading || dauLoading) {
    return (
      <div>
        <ClipLoader size={50} />
      </div>
    );
  }

  const chartData = [];
  for (const [key, value] of Object.entries(analytics)) {
    const color = {
      "users": "#6cb424",
      "executions": "#b425a5",
      "samples": "#d27620",
    }[key];
    chartData.push({
      chart: {
        zoomType: "x", backgroundColor: null, type: "line",
        style: {fontFamily: "Basier Circle"},
      },
      title: {
        text: key[0].toUpperCase() + key.slice(1),
        style: {fontFamily: "Basier Circle", color: "#37474F", fontWeight: "500"}
      },
      legend: {enabled: false},
      credits: {enabled: false},
      tooltip: {pointFormat: `<b>{point.y}</b> ${key}<br/>`},
      xAxis: {
        type: "datetime",
        labels: {style: {color: "#37474F", fontWeight: "500"}}
      },
      yAxis: {
        title: {
          enabled: false
        },
      },
      series: [{
        data: Object.entries(value).map(([k, v]) => [parseInt(`${k}000`), v]),
        color
      }]
    });
  }

  const dauData = {
    chart: {
      zoomType: "x", backgroundColor: null, type: "column",
      style: {fontFamily: "Basier Circle"},
    },
    title: {
      text: `Daily Active Users (${post ? "POST" : "GET"} requests)`,
      style: {fontFamily: "Basier Circle", color: "#37474F", fontWeight: "500"}
    },
    legend: {enabled: false},
    credits: {enabled: false},
    tooltip: {pointFormat: `<b>{point.y}</b> users<br/>`},
    xAxis: {
      type: "datetime",
      labels: {style: {color: "#37474F", fontWeight: "500"}}
    },
    yAxis: {
      title: {
        enabled: false
      },
    },
    series: [{
      data: Object.entries(post ? dau.dau_post : dau.dau_get).map(([k, v]) => [parseInt(`${k}000`), v]),
      color: "#4c9a89"
    }]
  };

  return (
    <div>
      <div className="grid gap-6 lg:grid-cols-2">
        {chartData.map((chart, index) => (
          <div className="" key={index}>
            <HighchartsReact
              highcharts={Highcharts} options={chart}
              containerProps={{style: {height: "100%"}}}
            />
          </div>
        ))}
        <div className="relative">
          <HighchartsReact
            highcharts={Highcharts} options={dauData}
            containerProps={{style: {height: "100%"}}}
          />
          <div
            className="text-xs absolute bottom-0 right-0 text-flow-blue-8 cursor-pointer hover:underline"
            onClick={() => setPost(!post)}
          >
            Show {post ? "GET" : "POST"} requests
          </div>
        </div>
      </div>
    </div>
  );
};

UsagePanel.propTypes = {
  
};

export default UsagePanel;