import { useState } from "react";
import Base from "./Base";
import { useDocumentTitle, useFetch, useLazyFetch } from "../hooks";
import { useNavigate, useParams } from "react-router-dom";
import Tabs from "../components/Tabs";
import { ClipLoader } from "react-spinners";
import Checkbox from "../components/Checkbox";
import { fileSize } from "../utils";
import DataTable2 from "../components/DataTable";
import ProjectsTable from "../components/ProjectsTable";
import ExecutionsTable2 from "../components/ExecutionsTable";
import SampleTable2 from "../components/SamplesTable";
import RangeSlider from "../components/RangeSlider";
import Button from "../components/Button";

const SearchPage = () => {

  const urlType = useParams().type;
  const tab = urlType ? urlType[0].toUpperCase() + urlType.slice(1) : "Projects";

  const navigate = useNavigate();

  const sizes = [...[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => 10 ** (i + 1)).map(
    i => [1, 2, 3, 4, 5, 6, 7, 8, 9].map(j => i * j)
  ).flat(), 1000000000000];

  const durations = [
    60, 120, 300, 600, 900, 1200, 1800, 2400,
    3600, 7200, 10800, 14400, 18000, 21600, 25200, 28800, 32400, 36000, 39600, 43200, 46800, 50400, 54000, 57600, 61200, 64800, 68400, 72000, 79200, 86400,
    129600, 172800,
  ]

  const [projects, setProjects] = useState(null);
  const [samples, setSamples] = useState(null);
  const [executions, setExecutions] = useState(null);
  const [data, setData] = useState(null);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(null);
  const [hasContent, setHasContent] = useState(null);
  const [name, setName] = useState("");
  const [owner, setOwner] = useState("");
  const [createdGt, setCreatedGt] = useState(0);
  const [createdLt, setCreatedLt] = useState("");
  const [sizeGt, setSizeGt] = useState(0);
  const [sizeLt, setSizeLt] = useState(sizes.length + 1);
  const [durationGt, setDurationGt] = useState(0);
  const [durationLt, setDurationLt] = useState(durations.length + 1);
  const [type, setType] = useState("");
  const [processExecution, setProcessExecution] = useState("");
  const [status, setStatus] = useState("");
  const [pipeline, setPipeline] = useState("");
  const [pipelineVersion, setPipelineVersion] = useState("");
  const [nextflowVersion, setNextflowVersion] = useState("");
  const [category, setCategory] = useState(null);
  const [description, setDescription] = useState("");
  const [terminal, setTerminal] = useState("");
  const [organism, setOrganism] = useState("");
  const [project, setProject] = useState("");
  const [sequencer, setSequencer] = useState("");
  const [source, setSource] = useState("");
  const [purificationTarget, setPurificationTarget] = useState("");

  const pageSize = 50;

  const dateToUnix = date => {
    if (!date) return null;
    return new Date(date).getTime() / 1000;
  }

  const params = {page: page, count: pageSize};

  const projectParams = {
    ...params,
    name: name || null,
    description: description || null,
    owner: owner || null,
    created_gt: dateToUnix(createdGt),
    created_lt: dateToUnix(createdLt),
    sample_types: type || null,
    pipeline: pipeline || null,
  }

  const sampleParams = {
    ...params,
    name: name || null,
    organism: organism || null,
    owner: owner || null,
    created_gt: dateToUnix(createdGt),
    created_lt: dateToUnix(createdLt),
    sample_types: type || null,
    project: project || null,
    sequencer: sequencer || null,
    source: source || null,
    purification_target: purificationTarget || null,
  }

  const executionParams = {
    ...params,
    status: status === "-" ? "" : (status || null),
    owner: owner || null,
    created_gt: dateToUnix(createdGt),
    created_lt: dateToUnix(createdLt),
    duration_gt: durationGt === 0 ? null : durations[durationGt - 1],
    duration_lt: durationLt === durations.length + 1 ? null : durations[durationLt - 1],
    process_execution: processExecution || null,
    pipeline: pipeline || null,
    pipeline_version: pipelineVersion || null,
    nextflow_version: nextflowVersion || null,
    terminal: terminal || null,
  }

  const dataParams = {
    ...params,
    filename: name || null,
    owner: owner || null,
    created_gt: dateToUnix(createdGt),
    created_lt: dateToUnix(createdLt),
    size_gt: sizeGt === 0 ? null : sizes[sizeGt - 1],
    size_lt: sizeLt === sizes.length + 1 ? null : sizes[sizeLt - 1],
    data_types: type || null,
    process_execution: processExecution || null,
    pipeline: pipeline || null,
    category: category || null,
  }

  const { loading, data: info } = useFetch("/search/info");

  const { loading: initialProjectsLoading } = useFetch("/projects/search", {
    skip: loading || tab !== "Projects" || projects,
    onCompleted: data => {
      setCount(data.count);
      setProjects(data.projects);
      if (hasContent === null) setHasContent(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / pageSize);
      if (page > maxPossiblePage) setPage(maxPossiblePage || 1);
    },
    params: projectParams,
  })

  const { loading: initialSamplesLoading } = useFetch("/samples/search", {
    skip: loading || tab !== "Samples" || samples,
    onCompleted: data => {
      setCount(data.count);
      setSamples(data.samples);
      if (hasContent === null) setHasContent(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / pageSize);
      if (page > maxPossiblePage) setPage(maxPossiblePage || 1);
    },
    params: sampleParams,
  })

  const { loading: initialExecutionsLoading } = useFetch("/executions/search", {
    skip: loading || tab !== "Executions" || executions,
    onCompleted: data => {
      setCount(data.count);
      setExecutions(data.executions);
      if (hasContent === null) setHasContent(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / pageSize);
      if (page > maxPossiblePage) setPage(maxPossiblePage || 1);
    },
    params: executionParams,
  })

  const {loading: initialDataLoading } = useFetch("/data/search", {
    skip: loading || tab !== "Data" || data,
    onCompleted: data => {
      setCount(data.count);
      setData(data.data);
      if (hasContent === null) setHasContent(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / pageSize);
      if (page > maxPossiblePage) setPage(maxPossiblePage || 1);
    },
    params: dataParams,
  })

  const [{loading: projectsLoading }, searchProjects] = useLazyFetch("/projects/search", {
    onCompleted: data => {
      setCount(data.count);
      setProjects(data.projects);
      if (hasContent === null) setHasContent(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / pageSize);
      if (page > maxPossiblePage) setPage(maxPossiblePage || 1);
    },
    params: projectParams,
  })

  const [{loading: samplesLoading }, searchSamples] = useLazyFetch("/samples/search", {
    onCompleted: data => {
      setCount(data.count);
      setSamples(data.samples);
      if (hasContent === null) setHasContent(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / pageSize);
      if (page > maxPossiblePage) setPage(maxPossiblePage || 1);
    },
    params: sampleParams,
  })

  const [{loading: executionsLoading }, searchExecutions] = useLazyFetch("/executions/search", {
    onCompleted: data => {
      setCount(data.count);
      setExecutions(data.executions);
      if (hasContent === null) setHasContent(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / pageSize);
      if (page > maxPossiblePage) setPage(maxPossiblePage || 1);
    },
    params: executionParams,
  })

  const [{ loading: dataLoading }, searchData] = useLazyFetch("/data/search", {
    onCompleted: data => {
      setCount(data.count);
      setData(data.data);
      if (hasContent === null) setHasContent(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / pageSize);
      if (page > maxPossiblePage) setPage(maxPossiblePage || 1);
    },
    params: dataParams,
  })

  const search = page => {
    const params = tab === "Projects" ? projectParams : tab === "Samples" ? sampleParams : tab === "Executions" ? executionParams : dataParams;
    params.page = page || 1;
    if (!page) setPage(1);
    if (tab === "Projects") searchProjects({params});
    if (tab === "Samples") searchSamples({params});
    if (tab === "Executions") searchExecutions({params});
    if (tab === "Data") searchData({params});
  }

  const changePage = page => {
    setPage(page);
    search(page);
  }

  useDocumentTitle("Advanced Search - Flow");

  if (loading) return <Base loading={true} />;

  const selectedSizeGt = sizeGt === 0 ? null : sizes[sizeGt - 1];
  const selectedSizeLt = sizeLt === sizes.length + 1 ? null : sizes[sizeLt - 1];
  const selectedDurationGt = durationGt === 0 ? null : durations[durationGt - 1];
  const selectedDurationLt = durationLt === durations.length + 1 ? null : durations[durationLt - 1];

  const setTab = tabName => {
    navigate(`/search/${tabName.toLowerCase()}/`);
    setProjects(null);
    setSamples(null);
    setExecutions(null);
    setData(null);
    setPage(1);
    setCount(null);
    setHasContent(null);
    setName("");
    setOwner("");
    setCreatedGt("");
    setCreatedLt("");
    setSizeGt(0);
    setSizeLt(sizes.length + 1);
    setDurationGt(0);
    setDurationLt(durations.length + 1);
    setType("");
    setStatus("");
    setProcessExecution("");
    setPipeline("");
    setPipelineVersion("");
    setNextflowVersion("");
    setCategory(null);
    setDescription("");
    setTerminal("");
    setOrganism("");
    setProject("");
    setSequencer("");
    setSource("");
    setPurificationTarget("");
  };


  const formatSize = size => {
    if (size === null) return "";
    return fileSize(size).replace(".00", "").replace(".0", "");
  }

  const formatDuration = seconds => {
    if (seconds === null) return "";
    if (seconds === 60) return "1m";
    if (seconds < 3600) return `${seconds / 60}m`;
    return `${seconds / 3600}h`;
  }

  const labelClass = "text-sm text-flow-gray-7 font-semibold";
  const inputClass = "bg-flow-gray-2 rounded py-1.5 text-xs text-flow-blue-7 w-full font-medium w-full outline-none";

  const sampleTypes = info.sample_types;
  const dataTypes = info.data_types;
  const nextflowVersions = info.nextflow_versions;
  const organisms = info.organisms;
  const pipelines = info.pipelines;
  const pipelinesById = pipelines.reduce((p, c) => ({...p, [c.id]: c}), {});

  const pipelineChanged = value => {
    setPipeline(value);
    setPipelineVersion("");
  }

  const statuses = [
    {id: "-", value: "RUNNING"},
    {id: "OK", value: "COMPLETED"},
    {id: "ERROR", value: "FAILED"},
    {id: "CANCELED", value: "CANCELLED"},
  ]

  const types = (tab === "Samples" || tab === "Projects") ? sampleTypes : tab === "Data" ? dataTypes : [];

  const columnClass = "flex flex-col gap-4";

  return (
    <Base className="flex flex-col">
      <Tabs labels={["Projects", "Samples", "Executions", "Data"]} selected={tab} setSelected={setTab} />

      <div className="flex flex-col gap-y-8 flex-1 w-full flex-grow h-fit max-h-[calc(100% - 1rem)] lg:overflow-auto lg:flex-row">        
        <div className="w-full flex-shrink-0 border-b pb-10 flex flex-col gap-4 md:grid md:grid-cols-2 lg:flex lg:flex-col lg:overflow-auto md:pr-4 lg:pb-0 lg:border-r lg:w-72">

          <div className={columnClass}>
            {tab === "Executions" && (
              <div>
                <label className={labelClass}>Status</label>
                <select
                  className={inputClass}
                  value={status}
                  onChange={e => setStatus(e.target.value)}
                >
                  <option value="">All</option>
                  {statuses.map(t => (
                    <option key={t.id} value={t.id}>{t.value}</option>
                  ))}
                </select>
              </div>
            )}
          
            {tab !== "Executions" && (
              <div>
                <label className={labelClass} htmlFor="name">
                  {tab === "Data" ? "Filename" : "Name"}
                </label>
                <input
                  id="name"
                  type="text"
                  value={name} 
                  className={inputClass}
                  onChange={e => setName(e.target.value)}
                />
              </div>
            )}

            {tab === "Samples" && (
              <div>
                <label className={labelClass}>Organism</label>
                <select
                  className={inputClass}
                  value={organism}
                  onChange={e => setOrganism(e.target.value)}
                >
                  <option value="">All</option>
                  {organisms.map(o => (
                    <option key={o.id} value={o.id}>{o.name}</option>
                  ))}
                </select>
              </div>
            )}

            {tab === "Projects" && (
              <div>
                <label className={labelClass}>Description</label>
                <input
                  type="text"
                  value={description} 
                  className={inputClass}
                  onChange={e => setDescription(e.target.value)}
                />
              </div>
            )}

            <div>
              <label className={labelClass} htmlFor="owner">Owner</label>
              <input
                id="owner"
                type="text"
                value={owner} 
                className={inputClass}
                onChange={e => setOwner(e.target.value)}
              />
            </div>

            <div>
              <label className={labelClass}>Created Between</label>
              <div className="flex flex-col gap-2 sm:flex-row md:flex-col lg:flex-row">
                <input
                  type="date"
                  value={createdGt} 
                  className={inputClass}
                  onChange={e => setCreatedGt(e.target.value)}
                />
                <input
                  type="date"
                  value={createdLt} 
                  className={inputClass}
                  onChange={e => setCreatedLt(e.target.value)}
                />
              </div>
            </div>

            {tab === "Data" && (
              <div className="">
                <div className="flex justify-between">
                  <label className={labelClass}>File Size</label>
                  <div className="text-flow-gray-6 font-medium text-xs">
                    {formatSize(selectedSizeGt)}
                    {sizeGt !== 0 && sizeLt !== sizes.length + 1 && " - "}
                    {sizeGt !== 0 && sizeLt === sizes.length + 1 && "+"}
                    {sizeGt === 0 && sizeLt === sizes.length + 1 && "All sizes"}
                    {formatSize(selectedSizeLt)}
                    {sizeGt === 0 && sizeLt !== sizes.length + 1 && " (max)"}
                  </div>
                </div>
                <div className="relative mb-1 w-full mt-2 mx-auto" style={{width: "calc(100% - 1rem)"}}> 
                  <RangeSlider
                    min={0}
                    max={sizes.length + 1}
                    value1={sizeGt}
                    value2={sizeLt}
                    setValue1={setSizeGt}
                    setValue2={setSizeLt}
                  />
                </div>
              </div>
            )}

            {tab === "Executions" && (
              <div className="">
                <div className="flex justify-between">
                  <label className={labelClass}>Duration</label>
                  <div className="text-flow-gray-6 font-medium text-xs">
                    {formatDuration(selectedDurationGt)}
                    {durationGt !== 0 && durationLt !== durations.length + 1 && " - "}
                    {durationGt !== 0 && durationLt === durations.length + 1 && "+"}
                    {durationGt === 0 && durationLt === durations.length + 1 && "All durations"}
                    {formatDuration(selectedDurationLt)}
                    {durationGt === 0 && durationLt !== durations.length + 1 && " (max)"}
                  </div>
                </div>
                <div className="relative mb-1 w-full mt-2 mx-auto" style={{width: "calc(100% - 1rem)"}}> 
                  <RangeSlider
                    min={0}
                    max={durations.length + 1}
                    value1={durationGt}
                    value2={durationLt}
                    setValue1={setDurationGt}
                    setValue2={setDurationLt}
                  />
                </div>
              </div>
            )}
          </div>
          
          <div className={columnClass}>
            {types.length > 0 && (
              <div>
                <label className={labelClass}>
                  {tab === "Projects" ? "Sample Types" : "Type"}
                </label>
                <select
                  className={inputClass}
                  value={type}
                  onChange={e => setType(e.target.value)}
                >
                  <option value="">All</option>
                  {types.map(t => (
                    <option key={t.id} value={t.id}>{t.name}</option>
                  ))}
                </select>
              </div>
            )}

            {["Executions", "Data"].includes(tab) && (
              <div>
                <label className={labelClass}>
                  {tab === "Executions" ? "Has " : "From "}Process Execution
                </label>
                <input
                  type="text"
                  value={processExecution} 
                  className={inputClass}
                  onChange={e => setProcessExecution(e.target.value)}
                />
              </div>
            )}

            {["Projects", "Data", "Executions"].includes(tab) && (
              <div>
                <label className={labelClass}>
                  {tab === "Projects" ? "Analysed with" : "From Pipeline"}
                </label>
                <select
                  className={inputClass}
                  value={pipeline}
                  onChange={e => pipelineChanged(e.target.value)}
                >
                  <option value="">All</option>
                  {pipelines.map(p => (
                    <option key={p.id} value={p.id}>{p.name}</option>
                  ))}
                </select>
              </div>
            )}

            {tab === "Executions" && pipeline && (
              <div>
                <label className={labelClass}>Pipeline Version</label>
                <select
                  className={inputClass}
                  value={pipelineVersion}
                  onChange={e => setPipelineVersion(e.target.value)}
                >
                  <option value="">All</option>
                  {pipelinesById[pipeline].versions.map(p => (
                    <option key={p.id} value={p.id}>{p.name}</option>
                  ))}
                </select>
              </div>
            )}

            {tab === "Executions" && (
              <div>
                <label className={labelClass}>Nextflow Version</label>
                <select
                  className={inputClass}
                  value={nextflowVersion}
                  onChange={e => setNextflowVersion(e.target.value)}
                >
                  <option value="">All</option>
                  {nextflowVersions.map(n => (
                    <option key={n} value={n}>{n}</option>
                  ))}
                </select>
              </div>
            )}

            {tab === "Executions" && (
              <div>
                <label className={labelClass}>Terminal Output</label>
                <input
                  type="text"
                  value={terminal}
                  className={inputClass}
                  onChange={e => setTerminal(e.target.value)}
                />
              </div>
            )}

            {tab === "Samples" && (
              <div>
                <label className={labelClass}>Project</label>
                <input
                  type="text"
                  value={project}
                  className={inputClass}
                  onChange={e => setProject(e.target.value)}
                />
              </div>
            )}

            {tab === "Samples" && (
              <div>
                <label className={labelClass}>Sequencer</label>
                <input
                  type="text"
                  value={sequencer}
                  className={inputClass}
                  onChange={e => setSequencer(e.target.value)}
                />
              </div>
            )}

            {tab === "Samples" && (
              <div>
                <label className={labelClass}>Source (cell/tissue)</label>
                <input
                  type="text"
                  value={source}
                  className={inputClass}
                  onChange={e => setSource(e.target.value)}
                />
              </div>
            )}

            {tab === "Samples" && (
              <div>
                <label className={labelClass}>Purification Target</label>
                <input
                  type="text"
                  value={purificationTarget}
                  className={inputClass}
                  onChange={e => setPurificationTarget(e.target.value)}
                />
              </div>
            )}

            {tab === "Data" && (
              <div>
                <label className={labelClass}>Category</label>
                <div className="flex flex-col gap-1.5 text-xs font-medium mt-1 text-flow-blue-8">
                  <div className="flex items-center">
                    <Checkbox
                      checked={category === 2}
                      onChange={() => setCategory(category === 2 ? null : 2)}
                    />
                    Samplesheet only
                  </div>
                  <div className="flex items-center">
                    <Checkbox
                      checked={category === 3}
                      onChange={() => setCategory(category === 3 ? null : 3)}
                    />
                    <div>Multiplexed only</div>
                  </div>
                  <div className="flex items-center">
                    <Checkbox
                      checked={category === 4}
                      onChange={() => setCategory(category === 4 ? null : 4)}
                    />
                    Demultiplexed only
                  </div>

                </div>
              </div>
            )}
            <Button
              className="btn-primary"
              onClick={() => search()}
            >
              Search
            </Button>
          </div>

        </div>
        <div className="flex-1 overflow-auto w-full h-full lg:pl-4">
          {(initialDataLoading || initialExecutionsLoading || initialSamplesLoading || initialProjectsLoading) && !hasContent && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {hasContent && tab === "Data" && (
            <DataTable2
              data={data}
              page={page}
              pageSize={pageSize}
              setPage={changePage}
              totalCount={count}
              loading={dataLoading || initialDataLoading}
              showHeader={true}
              noMessage="No data matches that term." 
            />
          )}
          {hasContent && tab === "Executions" && (
            <ExecutionsTable2
              executions={executions}
              page={page}
              pageSize={pageSize}
              setPage={changePage}
              totalCount={count}
              loading={executionsLoading || initialExecutionsLoading}
              showHeader={true}
              noMessage="No executions match that term."
            />
          )}
          {hasContent && tab === "Samples" && (
            <SampleTable2
              samples={samples}
              page={page}
              pageSize={pageSize}
              setPage={changePage}
              totalCount={count}
              loading={samplesLoading || initialSamplesLoading}
              showHeader={true}
              noMessage="No samples match that term."
            />
          )}
          {hasContent && tab === "Projects" && (
            <ProjectsTable
              projects={projects}
              page={page}
              pageSize={pageSize}
              setPage={changePage}
              totalCount={count}
              loading={projectsLoading || initialProjectsLoading}
              showHeader={true}
              noMessage="No projects match that term."
            />
          )}
        </div>
      </div>
    </Base>
  );
}

SearchPage.propTypes = {

}

export default SearchPage;