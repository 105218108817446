import { useContext, useState } from "react";
import Base from "./Base";
import Tabs from "../components/Tabs";
import { useFetch, useDocumentTitle, useLazyFetch } from "../hooks";
import ExecutionsTable2 from "../components/ExecutionsTable";
import { ClipLoader } from "react-spinners";
import { UserContext } from "../contexts";
import { Link, useParams } from "react-router-dom";

const YourExecutionPage = () => {

  const { slug } = useParams();

  const [tab, setTab] = useState("Owned");
  const [user,] = useContext(UserContext);
  const [ownedExecutionsPage, setOwnedExecutionsPage] = useState(1);
  const [ownedExecutionsCount, setOwnedExecutionsCount] = useState(null);
  const [ownedExecutions, setOwnedExecutions] = useState(null);
  const [ownedExecutionsFilter, setOwnedExecutionsFilter] = useState(null);
  const [hasOwnedExecutions, setHasOwnedExecutions] = useState(null);
  const [sharedExecutionsPage, setSharedExecutionsPage] = useState(1);
  const [sharedExecutionsCount, setSharedExecutionsCount] = useState(null);
  const [sharedExecutions, setSharedExecutions] = useState(null);
  const [sharedExecutionsFilter, setSharedExecutionsFilter] = useState(null);
  const [hasSharedExecutions, setHasSharedExecutions] = useState(null);
  const pageSize = 20;

  useDocumentTitle("Your Executions - Flow");

  const { loading: ownedExecutionsLoading } = useFetch("/executions/owned", {
    params: {page: ownedExecutionsPage, count: pageSize, filter: ownedExecutionsFilter, group: slug},
    onCompleted: data => {
      setOwnedExecutionsCount(data.count);
      setOwnedExecutions(data.executions);
      if (hasOwnedExecutions === null) setHasOwnedExecutions(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / pageSize);
      if (ownedExecutionsPage > maxPossiblePage) setOwnedExecutionsPage(maxPossiblePage || 1);
    }
  })

  const { loading: sharedExecutionsLoading } = useFetch("/executions/shared", {
    params: {page: sharedExecutionsPage, count: pageSize, filter: sharedExecutionsFilter, group: slug},
    onCompleted: data => {
      setSharedExecutionsCount(data.count);
      setSharedExecutions(data.executions);
      if (hasSharedExecutions === null) setHasSharedExecutions(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / pageSize);
      if (sharedExecutionsPage > maxPossiblePage) setSharedExecutionsPage(maxPossiblePage || 1);
    }
  })

  const [,postBulkDelete] = useLazyFetch("/executions/owned", {
    params: {page: ownedExecutionsPage, count: pageSize, filter: ownedExecutionsFilter},
    onCompleted: data => {
      setOwnedExecutionsCount(data.count);
      setOwnedExecutions(data.executions);
    }
  })

  const textClass = "text-sm mb-6 max-w-xl info sm:mb-8";

  const group = user.memberships.find(g => g.slug === slug);

  const ownedLabel = (hasOwnedExecutions === false) ? group ? "This group does not own any executions yet." : "You do not own any executions yet." : group ? "Executions owned by this group." : "Executions you own.";
  const sharedLabel = (hasSharedExecutions === false) ? group ? "This group has no shared executions yet." : "You do not have any shared executions yet." : group ? "Executions shared with this group." : "Executions shared with you.";
  const showOwnedTable = (hasOwnedExecutions === true);
  const showSharedTable = (hasSharedExecutions === true);

  return (
    <Base>
      <h1>{group ? group.name : "Your"} Executions</h1>
      <Tabs labels={["Owned", "Shared"]} selected={tab} setSelected={setTab} />
      {tab === "Owned" && (
        <div>
          <div className={textClass}>
            <span>{ownedLabel}</span>
            {user.memberships.length > 0 && !group && (
              <span>
                {" "}Your group{user.memberships.length > 1 ? "s" : ""} may also own executions:{" "}
                {user.memberships.map((g, i) => (
                  <Link key={g.slug} to={`/executions/@${g.slug}`} className="text-flow-blue-7">
                    {g.name}
                    {i < user.memberships.length - 1 ? ", " : "."}
                  </Link>
                ))}
              </span>
            )}
          </div>
          {ownedExecutionsLoading && !showOwnedTable && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {showOwnedTable && (
            <ExecutionsTable2
              executions={ownedExecutions}
              page={ownedExecutionsPage}
              pageSize={pageSize}
              setPage={setOwnedExecutionsPage}
              totalCount={ownedExecutionsCount}
              loading={ownedExecutionsLoading}
              onFilter={s => setOwnedExecutionsFilter(s || null)}
              onBulkDelete={postBulkDelete}
              noMessage="No owned executions matches that term." 
            />
          )}
        </div>
      )}
      {tab === "Shared" && (
        <div>
          <div className={textClass}>
            <span>{sharedLabel}</span>
            {user.memberships.length > 0 && !group && (
              <span>
                {" "}Your group{user.memberships.length > 1 ? "s" : ""} may also have executions shared with {user.memberships.length > 1 ? "them" : "it"}:{" "}
                {user.memberships.map((g, i) => (
                  <Link key={g.slug} to={`/executions/@${g.slug}`} className="text-flow-blue-7">
                    {g.name}
                    {i < user.memberships.length - 1 ? ", " : "."}
                  </Link>
                ))}
              </span>
            )}
          </div>
          {sharedExecutionsLoading && !showSharedTable && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {showSharedTable && (
            <ExecutionsTable2
              executions={sharedExecutions}
              page={sharedExecutionsPage}
              pageSize={pageSize}
              setPage={setSharedExecutionsPage}
              totalCount={sharedExecutionsCount}
              loading={sharedExecutionsLoading}
              onFilter={s => setSharedExecutionsFilter(s || null)}
              noMessage="No shared executions matches that term."
            />
          )}
        </div>
      )}
    </Base>
  );

}

YourExecutionPage.propTypes = {
  
};

export default YourExecutionPage;