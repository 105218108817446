import { useState } from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { ReactComponent as DownloadIcon } from "../images/download.svg";
import { useLazyFetch } from "../hooks";
import { useApolloClient } from "@apollo/client";
import { TOKEN } from "../queries";

const BulkDownloadButton = props => {

  const { selectedIds, isExecutions, isSamples } = props;

  const [loading, setLoading] = useState(false);

  const [,submitBulkDownload] = useLazyFetch(isExecutions ? "/downloads/executions" : isSamples ? "/downloads/samples" : "/downloads", {
    method: "POST",
    params: { ids: selectedIds },
  });

  const [,getBulkDownloadStatus] = useLazyFetch("/downloads/status");

  const client = useApolloClient();

  const download = jobId => {
    let token;
    try {
      const cacheValue = client.cache.readQuery({query: TOKEN});
      token = `?token=${cacheValue.accessToken}`;
    } catch {
      token = "";
    }
    const url = `${process.env.REACT_APP_DOWNLOADS}/${jobId}${token}`;
    const link = document.createElement("a");
    link.download = `${jobId}.zip`;
    link.href = url;
    link.click();
  }

  const submit = async () => {
    setLoading(true);
    const { data: job, error: submitError} = await submitBulkDownload();
    console.log(job)
    if (submitError) {
      setLoading(false);
      return;
    }
    let status = null;
    while (!status || !status.finished) {
      await new Promise(resolve => setTimeout(resolve, 1000));
      const resp = await getBulkDownloadStatus({params: {id: job.id}});
      if (resp.error) {
        setLoading(false);
        break;
      }
      status = resp.data;
    }
    if (!status?.finished) return;
    download(job.id);
    setLoading(false);
  }

  return (
    <Button
      className={`btn-primary gap-1 py-px text-xs px-1.5 ${selectedIds.length !== 0 || "opacity-20 pointer-events-none"} ${props.className || ""}`}
      onClick={submit}
      loading={loading}
    >
      <DownloadIcon className="w-3 h-auto" />
      Download
    </Button>
  );
};

BulkDownloadButton.propTypes = {
  selectedIds: PropTypes.array.isRequired,
  isExecutions: PropTypes.bool,
  isSamples: PropTypes.bool,
};

export default BulkDownloadButton;