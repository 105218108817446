import React from "react";
import PropTypes from "prop-types";
import Div100vh from "react-div-100vh";
import Nav from "../components/Nav";
import Sidebar from "../components/Sidebar";
import { MoonLoader } from "react-spinners";
import NotFound from "../components/NotFound";

const Base = props => {

  const { loading, notFound, isAdmin } = props;

  return (
    <Div100vh className="flex flex-col">
      <Nav />
      <div className="flex-grow flex sub-nav">
        <Sidebar />
        <main className={`overflow-auto ml-4 mb-4 top-16 mr-4 w-full bottom-4 p-4 bg-white rounded-md  ${isAdmin ? "border-4 border-yellow-500" : "border border-[#EEEEEE]"} xl:p-6 xl:mb-6 xl:mr-6  ${!(loading || notFound) || "flex justify-center items-center"} ${props.className || ""}`}>
          {loading && <MoonLoader color="#4163DB" size="60px" />}
          {notFound && <NotFound />}
          {!loading && !notFound &&  props.children}
        </main>
      </div>
    </Div100vh>
  );
};

Base.propTypes = {
  loading: PropTypes.bool
};

export default Base;