import { useRef, useState } from "react";
import { useFetch, useLazyFetch } from "../hooks";
import { ClipLoader } from "react-spinners";
import { Link } from "react-router-dom";

const RunQueue = () => {

  const [data, setData] = useState(null);
  const interval = useRef(null);

  const { loading } = useFetch("/queue", {
    onCompleted: data => setData(data)
  });

  const [{ loading: pollLoading}, pollQueue] = useLazyFetch("/queue", {
    onCompleted: data => setData(data)
  });

  if (loading) return <ClipLoader size={50} />

  if (!data || !Object.keys(data).length) return (
    <div className="info">
      The jobs queue is not supported on this instance.
    </div>
  )

  if (!interval.current) interval.current = setInterval(() => {
    pollQueue();
  }, 10000);

  if (data && data.rows.length === 0) return (
    <div className="info">
      The jobs queue is empty.
    </div>
  )

  const thClass = "font-medium text-sm text-flow-gray-7 bg-[#E7E9F0] bg-opacity-80 whitespace-nowrap py-1.5 text-left px-4";
  const cellClass = "whitespace-nowrap text-xs py-3 px-4";
  const linkClass = "text-flow-blue-7";

  return (
    <div className={`max-w-full w-fit overflow-x-auto rounded mt-4 ${(pollLoading) ? "border-flow-blue-2 ring italic ring-flow-blue-2 transition-all duration-1000" : "border-gray-500"}`}>
      <table>
        <thead>
          <th className={thClass}>Status</th>
          <th className={thClass}>Execution</th>
          <th className={thClass}>Process Execution</th>
          <th className={thClass}>Owner</th>
          {Object.values(data.extra_headings).map(heading => (
            <th className={thClass} key={heading}>{heading}</th>
          ))}
        </thead>
        <tbody>
          {data.rows.map((item, index) => (
            <tr className={`bg-opacity-80 ${index % 2 ? "bg-[#E7E9F0]" : "bg-[#F6F6F6]"}`} key={item.id}>
              <td className={cellClass}>{item.status}</td>
              {!item.execution && <td className={cellClass}>-</td>}
              {item.execution && (
                <td className={cellClass}><Link className={linkClass} to={`/executions/${item.execution.id}`}>{item.execution.name}</Link></td>
              )}
              {!item.execution?.process_execution_name && <td className={cellClass}>-</td>}
              {item.execution?.process_execution_name && <td className={cellClass}>{item.execution.process_execution_name}</td>}
              {!item.execution?.owner && !item.execution?.group_owner && <td>-</td>}
              {item.execution?.owner && (
                <td className={cellClass}><Link className={linkClass} to={`/users/${item.execution.owner.username}`}>{item.execution.owner.name}</Link></td>
              )}
              {item.execution?.group_owner && (
                <td className={cellClass}><Link className={linkClass} to={`/groups/@${item.execution.group_owner.slug}`}>{item.execution.group_owner.name}</Link></td>
              )}
              {Object.keys(data.extra_headings).map(key => (
                <td className={cellClass} key={key}>{item[key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

RunQueue.propTypes = {
  
};

export default RunQueue;