import { useState } from "react";
import { useFetch, useLazyFetch } from "../hooks";
import { ClipLoader } from "react-spinners";
import Button from "./Button";
import DataTypeEditModal from "./DataTypeEditModal";
import DataTypeDeletionModal from "./DataTypeDeletionModal";
import SampleTypeAdmin from "./SampleTypeAdmin";

const TypesAdminTab = () => {

  const [dataTypes, setDataTypes] = useState(null);
  const [dataTypeToEdit, setDataTypeToEdit] = useState(null);
  const [dataTypeToDelete, setDataTypeToDelete] = useState(null);
  const [newDataTypeId, setNewDataTypeId] = useState("");
  const [newDataTypeName, setNewDataTypeName] = useState("");
  const [newDataTypeDescription, setNewDataTypeDescription] = useState("");
  
  const [sampleTypes, setSampleTypes] = useState(null);
  const [sampleTypeToEdit, setSampleTypeToEdit] = useState(null);
  const [sampleTypeToDelete, setSampleTypeToDelete] = useState(null);
  const [newSampleTypeId, setNewSampleTypeId] = useState("");
  const [newSampleTypeName, setNewSampleTypeName] = useState("");
  const [newSampleTypeDescription, setNewSampleTypeDescription] = useState("");
  
  const [errorFields, setErrorFields] = useState([]);

  const { loading: dataTypesLoading } = useFetch("/data/types", {
    onCompleted: setDataTypes
  });

  const { loading: sampleTypesLoading } = useFetch("/samples/types", {
    onCompleted: setSampleTypes
  });

  const { loading: availableDataTypesLoading, data: availableDataTypes } = useFetch("/data/types/available");

  const { loading: availableSampleTypesLoading, data: availableSampleTypes } = useFetch("/samples/types/available");

  const [{ loading: dataTypeCreationLoading }, createDataType] = useLazyFetch("/data/types/create", {
    method: "POST",
    params: { id: newDataTypeId, name: newDataTypeName, description: newDataTypeDescription },
    onCompleted: data => {
      setErrorFields([]);
      setDataTypes([...dataTypes, data]);
      setNewDataTypeId("");
      setNewDataTypeName("");
      setNewDataTypeDescription("");
    },
    onError: error => {
      setErrorFields(Object.keys(error.error || {}));
    }
  });

  const [{ loading: sampleTypeCreationLoading }, createSampleType] = useLazyFetch("/samples/types/create", {
    method: "POST",
    params: { id: newSampleTypeId, name: newSampleTypeName, description: newSampleTypeDescription },
    onCompleted: data => {
      setErrorFields([]);
      setSampleTypes([...sampleTypes, data]);
      setNewSampleTypeId("");
      setNewSampleTypeName("");
      setNewSampleTypeDescription("");
    },
    onError: error => {
      setErrorFields(Object.keys(error.error || {}));
    }
  });

  if (dataTypesLoading || sampleTypesLoading || availableDataTypesLoading || availableSampleTypesLoading || !dataTypes) return <ClipLoader size={50} />;

  const canCreateDataType = newDataTypeId && newDataTypeName && newDataTypeDescription;
  const canCreateSampleType = newSampleTypeId && newSampleTypeName && newSampleTypeDescription;

  const inputClass = "outline-none";

  const filteredAvailableDataTypes = availableDataTypes.filter(type => !dataTypes.find(t => t.id === type));
  const filteredAvailableSampleTypes = availableSampleTypes.filter(type => !sampleTypes.find(t => t.id === type));

  return (
    <div className="flex flex-wrap gap-x-20 gap-y-12">
      <div>
        <div className="font-medium text-xl mb-6">Custom Sample Types</div>
        <div className="flex flex-col gap-10 w-fit">
          {sampleTypes.map(type => (
            <SampleTypeAdmin
              key={type.id}
              sampleType={type}
              setSampleType={t => setSampleTypes(sampleTypes.map(s => s.id === t.id ? t : s))}
              sampleTypes={sampleTypes}
              setSampleTypes={setSampleTypes}
            />
          ))}
          <div>
            <div className="font-medium mb-2">Create a new sample type</div>
            {filteredAvailableSampleTypes.length > 0 && (
              <div className="text-sm mb-3">
                The following sample types are referenced in pipeline schemas, but have no corresponding sample type:
                {filteredAvailableSampleTypes.map(type => (
                  <span key={type} className="ml-1 border rounded px-1 pb-0.5 bg-gray-50 font-medium leading-3">{type}</span>
                ))}
              </div>
            )}
            <div className="flex gap-1.5">
              <input
                placeholder="ID"
                className={`${inputClass} ${errorFields.includes("id") ? "text-red-600 bg-red-200" : "text-gray-700 pl-0"} text-xs w-24 pr-1.5 py-0.5 border-r rounded-none outline-none`}
                value={newSampleTypeId}
                onChange={e => setNewSampleTypeId(e.target.value)}
              />
              <input
                className={`${inputClass} ${errorFields.includes("name") ? "text-red-600 bg-red-200" : "text-gray-700 pl-0"} text-xs w-48 rounded-none `}
                placeholder="Name"
                value={newSampleTypeName}
                onChange={e => setNewSampleTypeName(e.target.value)}
              />
            </div>
            <input
              className={`${inputClass} ${errorFields.includes("description") ? "text-red-600 bg-red-200" : "text-gray-700 pl-0"} text-sm mt-1.5 py-1.5 rounded-none pl-0 border-t w-72`}
              placeholder="Description"
              value={newSampleTypeDescription}
              onChange={e => setNewSampleTypeDescription(e.target.value)}
            />

            <Button
              className={`block w-fit btn-primary py-1 mt-2 text-sm ${canCreateSampleType ? "" : "opacity-40 pointer-events-none"}`}
              loading={sampleTypeCreationLoading}
              onClick={createSampleType}
            >
              Create
            </Button>
          </div>
        </div>
      </div>
      <div>
        <div className="font-medium text-xl mb-6">Custom Data Types</div>
        <div className="flex flex-col gap-10 w-fit">
          {dataTypes.map(type => (
            <div key={type.id} className="border-b border-gray-300 pb-4">
              <div className="flex items-center gap-1 mb-1">
                <div className="text-xs border rounded px-1.5 py-0.5 bg-gray-50 text-gray-700 font-medium">{type.id}</div>
                <div>{type.name}</div>
                <button
                  className="text-blue-600 text-xs relative bottom-1.5 cursor-pointer"
                  onClick={() => setDataTypeToEdit(type)}
                >
                  Edit
                </button>
                <button
                  className="text-red-600 text-xs relative bottom-1.5 cursor-pointer"
                  onClick={() => setDataTypeToDelete(type)}
                >
                  Delete
                </button>
              </div>
              <div className="text-sm">{type.description}</div>
            </div>
          ))}
          <div>
            <div className="font-medium mb-2">Create a new data type</div>
            {filteredAvailableDataTypes.length > 0 && (
              <div className="text-sm mb-3">
                The following data types are referenced in pipeline schemas, but have no corresponding data type:
                {filteredAvailableDataTypes.map(type => (
                  <span key={type} className="ml-1 border rounded px-1 pb-0.5 bg-gray-50 font-medium leading-3">{type}</span>
                ))}
              </div>
            )}
            <div className="flex gap-1.5">
              <input
                placeholder="ID"
                className={`${inputClass} ${errorFields.includes("id") ? "text-red-600 bg-red-200" : "text-gray-700 pl-0"} text-xs w-24 pr-1.5 py-0.5 border-r rounded-none outline-none`}
                value={newDataTypeId}
                onChange={e => setNewDataTypeId(e.target.value)}
              />
              <input
                className={`${inputClass} ${errorFields.includes("name") ? "text-red-600 bg-red-200" : "text-gray-700 pl-0"} text-xs w-48 rounded-none `}
                placeholder="Name"
                value={newDataTypeName}
                onChange={e => setNewDataTypeName(e.target.value)}
              />
            </div>
            <input
              className={`${inputClass} ${errorFields.includes("description") ? "text-red-600 bg-red-200" : "text-gray-700 pl-0"} text-sm mt-1.5 py-1.5 rounded-none pl-0 border-t w-72`}
              placeholder="Description"
              value={newDataTypeDescription}
              onChange={e => setNewDataTypeDescription(e.target.value)}
            />

            <Button
              className={`block w-fit btn-primary py-1 mt-2 text-sm ${canCreateDataType ? "" : "opacity-40 pointer-events-none"}`}
              loading={dataTypeCreationLoading}
              onClick={createDataType}
            >
              Create
            </Button>
          </div>
        </div>
        {dataTypeToDelete && (
          <DataTypeDeletionModal
            dataType={dataTypeToDelete}
            dataTypes={dataTypes}
            setDataTypes={setDataTypes}
            setShow={setDataTypeToDelete}
          />
        )}
        {dataTypeToEdit && (
          <DataTypeEditModal
            dataType={dataTypeToEdit}
            dataTypes={dataTypes}
            setDataTypes={setDataTypes}
            setShow={setDataTypeToEdit}
          />
        )}
      </div>
    </div>
  );
};

TypesAdminTab.propTypes = {
  
};

export default TypesAdminTab;